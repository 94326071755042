<template>
    <div class="flex_layout order">
        <div class="flex_top" v-if="isShowQuery">
            <div class="query flex">
                <a-space>
                    <div class="query_box">
                        <a-input v-model:value="query.order_no" placeholder="Order no" />
                    </div>
                    <div class="query_box">
                        <a-button class="primary btn" @click="getOrderListHandler">Search</a-button>
                    </div>
                    <div class="query_box" @click="createOrderHandler" v-if="getAction(1)">
                        <a-button>Create Order</a-button>
                    </div>
                </a-space>
            </div>
        </div>
        <div class="flex_body">
            <a-spin :spinning="orderSpinning">
               <a-table :dataSource="dataSource" :columns="columns" :pagination="false">
                   <template #date="{record}">
                       <span>{{getDate(record.created_time)}}</span>
                   </template>
                   <template #source="{record}">
                       <span>{{getSource(record.source)}}</span>
                   </template>
                   <template #state="{record}">
                       <span>{{record.state == 0 ? 'Pedding' : 'Finish'}}</span>
                   </template>
                   <template #action="{ record }">
                       <a-space>
                           <a-button class="primary btn" @click="check(record)" size="small" type="primary">Get detail</a-button>
                           <a-popconfirm
                                title="Are you sure delete this?"
                                ok-text="Yes"
                                cancel-text="No"
                                @confirm="() => remove(record.order_id)"
                            >
                               <a-button danger size="small" v-if="!isLatest && isAdmin">Delete</a-button>
                           </a-popconfirm>
                       </a-space>
                   </template>
               </a-table>
            </a-spin>
        </div>
        <div class="flex_bottom" v-if="isShowPagination">
            <a-pagination size="small" v-model:current="query.page" :total="total" show-less-items />
        </div>
        <a-drawer
            title="Order"
            placement="bottom"
            height="100%"
            :visible="visible"
            @close="onClose"
            :drawerStyle="{background: '#f9f9f9'}"
            >
               <div class="container">
                   <a-spin :spinning="orderSpinning">
                       <OrderInfo @onRefresh="onRefresh" :orderInfo="orderInfo" />
                   </a-spin>
               </div>
        </a-drawer>
        <a-drawer
            title="Create order"
            placement="bottom"
            height="100%"
            :visible="orderVisible"
            @close="onOrderClose"
            :drawerStyle="{background: '#f9f9f9'}"
            >
               <div class="container">
               </div>
        </a-drawer>
    </div>
</template>
<script>
const columns = [
    {
        title: 'Order no',
        dataIndex: 'order_no',
        key: 'order_no',
    },
    {
        title: 'Name',
        dataIndex: 'name',
        key: 'name',
    },
    {
        title: 'Phone',
        dataIndex: 'phone',
        key: 'phone',
    },
    {
        title: 'Email',
        dataIndex: 'email',
        key: 'email',
    },
    {
        title: 'Address',
        dataIndex: 'address',
        key: 'address',
    },
    {
        title: 'Source',
        slots: { customRender: 'source' },
    },
    {
        title: 'Cost',
        dataIndex: 'cost',
        key: 'cost',
    },
    {
        title: 'State',
        slots: { customRender: 'state'}
    },
    {
        title: 'Order date',
        slots: { customRender: 'date'}
    },
    {
        title: 'Action',
        key: 'action',
        slots: { customRender: 'action' },
    },
]
import moment from 'moment'
import commonMixins from '@/mixins/commonMixins'
import Utils from '@/utils/Utils'
import { getLatestOrderList, getOrderList, removeOrder } from '@/apis/order'
import { getOrderListForEmployee } from '@/apis/employee/order'
import OrderInfo from '@/components/admin/order/orderInfo'
import storage from '@/utils/storage'
export default {
    name: 'Order',
    mixins: [commonMixins],
    props: {
        isShowQuery: {
            type: Boolean,
            default: true
        },
        isShowPagination: {
            type: Boolean,
            default: true
        },
        isLatest: {
            type: Boolean,
            default: false
        },
        isAdmin: { 
            type: Boolean,
            default: true
        }
    },
    components: {
        OrderInfo
    },
    data(){
        return {
            dataSource: [],
            columns,
            query: {
                order_no: '',
                page: 1,
                page_size: 10
            },
            total: 1,
            orderSpinning: false,
            visible: false,
            orderInfo: {},
            orderStreams: [],
            streamInfo: [],
            orderVisible: false
        }
    },
    methods: {
        /**
         * 获取最新订单列表
         */
        async getLatestOrderListHandler(){
            this.orderSpinning = true
            const res = await getLatestOrderList({page: 1})
            this.orderSpinning = false
            this.dataSource = res.list.rows
            this.orderStreams=res.list.ts
        },
        /**
         * 获取订单列表
         */
        async getOrderListHandler(){
            this.orderSpinning = true
            const res = await getOrderList(this.query)
            console.log(res)
            this.orderSpinning = false
            this.dataSource = res.list.rows.reverse()
            this.orderStreams=res.list.ts
            this.total = res.list.count
        },
        async getOrderListForEmployeeHandler(){
            this.orderSpinning = true
            const res = await getOrderListForEmployee(this.query)
            console.log(res)
            this.orderSpinning = false
            this.dataSource = res.list.rows
            this.orderStreams=res.list.ts
            this.total = res.list.count
        },
        /**
         * 创建订单
         */
        createOrderHandler(){
            this.orderInfo = {
                action_type: 'add', 
                name: '',
                phone: '',
                email: '',
                address: '',
                instation_date: '',
                have_system: false,
                source: 2,
                detail: [],
                order_no: '',
                cost: 0,
                state: 0,
                emp_id: storage.getLocalData('emp_id'),
                emp_name: 0
            }
            this.visible = true
        },
        check(orderInfo){
            let that=this
            orderInfo.action_type = 'edit'
            this.orderInfo = orderInfo
            this.streamInfo=[]
            this.orderStreams.forEach(function(item){
                if(orderInfo.order_id==item.task_id){
                    that.streamInfo=item
                }
            })
            this.orderInfo.orderStreams=this.streamInfo
            console.log(this.orderInfo)
            this.visible = true
        },
        onClose(){
            this.visible = false
        },
        onOrderClose(){
            this.orderVisible = false
        },
        async remove(order_id){
            this.spinning = true
            const res = await removeOrder({order_id})
            this.spinning = false
            if(res){
                this.init()
            } 
        },
        onRefresh(){
            this.getOrderListForEmployeeHandler()
        },
        init(){
            if(this.isLatest){
                this.getLatestOrderListHandler()
            }else{
                if(this.isAdmin){
                    this.getOrderListHandler()
                }else{
                    this.getOrderListForEmployeeHandler()
                }
            }
        }
    },
    created(){
        this.init()
    },
    computed: {
        getDate(){
            return date => {
                return Utils.toDate(Number(date))
            }
        },
        getSource(){
            return source => {
                switch(source){
                    case '0' :
                        return 'Recommended'
                    case '1' :
                        return 'Diy store'
                    case '2' :
                        return 'Employee'
                }
            }
        }
    }
}
</script>
<template>
    <div class="wrap">
        <div class="main_title">Order</div>
        <OrderComponents />
    </div>
</template>
<script>
import OrderComponents from '@/components/admin/order/order'
export default {
    name: 'Order',
    components: {
        OrderComponents
    }
}
</script>
<style lang="stylus" scoped>
</style>
import request from '@/request'

export const getOrderList = (data = {}) => {
    return request({
      url: '/admin/order/get_order_list',
      method: 'get',
      params: data
    })
}

export const getOrderStatistics = (data = {}) => {
    return request({
      url: '/admin/order/get_order_statistics',
      method: 'get',
      params: data
    })
}

export const getLatestOrderList = (data = {}) => {
  return request({
    url: '/admin/order/get_latest_order_list',
    method: 'get',
    params: data
  })
}
export const removeOrder = (data = {}) => {
  return request({
    url: '/admin/order/remove_order',
    method: 'post',
    data
  })
}